:root {
  --primary: #46c154;
  --primary-hover: #33a741;
  --secondary: #097700;
  --secondary-hover: #0c5f05;
  --background-color: #f7f7f7;
  --dark-background-color: #282d34;
  --background-color-button: #e3e3e3;
  --dark-background-color-button: #424953;
  --red-button-color: #9a0000;
  --normal-button-hover-start: #ededed;
  --normal-button-hover-end: #c3c3c3;
  --button-primary-border: #298400;
  --button-normal-border: #c7c7c7;
  --font-sans: 'Roboto', -apple-system, system-ui, BlinkMacSystemFont,
    'Helvetica Neue', 'Helvetica', sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
  font-family: var(--font-sans);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
