.container-scroll::-webkit-scrollbar {
  @apply hidden;
}

.container-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.container-scroll > .container-child:last-child {
  @apply pr-[60px];
}

.leasing:nth-child(even) {
  @apply bg-[#F7F7F7];
}
.leasing:last-child {
  @apply border border-green-600;
}
