@import 'react-range-slider-input/dist/style.css';

#range-slider .range-slider__range {
  background: black;
}

#range-slider .range-slider__thumb {
  background: black;
}

#range-slider .range-slider__thumb:before {
  position: absolute;
  display: block;
  content: '';
  width: 1px;
  height: 40%;
  top: 30%;
  left: calc(50% + 1px);
  background: #fff;
  cursor: pointer;
}

#range-slider .range-slider__thumb:after {
  position: absolute;
  display: block;
  content: '';
  width: 1px;
  height: 40%;
  top: 30%;
  left: calc(50% - 2px);
  background: #fff;
  cursor: pointer;
}
