.card-box div:first-child {
  border: 1px solid #c7c7c7 !important;
  border-bottom: 0 !important;
}
.card-box div:last-child {
  border: 1px solid #c7c7c7 !important;
  border-top: 0 !important;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
}
.card-box div:last-child p {
  margin: 0;
  padding-bottom: 20px;
}
.card-box h3 {
  border-left: 1px solid #c7c7c7 !important;
  border-right: 1px solid #c7c7c7 !important;
  margin: 0 !important;
  padding: 15px 20px !important;
}
.card-box {
  width: 25% !important;
  padding-left: 8px;
  padding-right: 8px;
}
.teaser-red .teaser-headline {
  margin-top: 50px !important;
}
.teaser-red .mpb-column-group {
  background: #b90202;
}
@media only screen and (max-width: 900px) {
  .card-box {
    width: 100%;
  }
  .mpb-col {
    width: 100% !important;
  }
}
@media only screen and (max-width: 868px) {
  .card-box {
    display: none;
  }
  .frank-rosin {
    display: none;
  }
  .mpb-column-group {
    display: block !important;
  }
}
@media only screen and (min-width: 767px) {
  .teaser-red .mpb-column-group .image-relative {
    padding-left: 17px !important;
    padding-right: 25px !important;
  }
}

.pagebuilder-image-wrapper img {
  display: inline-block;
}

.second-choice-cms {
  .row-element,
  .col-group-element,
  .col-element {
    padding-left: 0;
    padding-right: 0;
  }

  ul {
    padding-left: 0;
    margin-top: 0;
    margin-left: 0;
  }

  li {
    display: flex;
    margin-bottom: 9px;
    line-height: 21px;
    font-size: 16px;
    font-weight: 400;
    &:before {
      box-sizing: border-box;
      margin-right: 10px;
      width: 16px;
      min-width: 16px;
      height: 20px;
      min-height: 20px;
      background: url('/vsf/svg/checkmark/checkmark.svg') no-repeat center
        center;
      content: ' ';
    }

    a {
      color: #345581;
      text-decoration: underline;

      &:hover {
        color: #3b73cc;
      }
    }
  }
}
