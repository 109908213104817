@import './base.css';
@import './icomoon.css';
@import './mpb.css';
@import './slider.css';
@import './header.css';
@import './MobileMenu.css';
@import './range-slider.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-last-center {
    text-align-last: center;
  }
}

body {
  @apply text-sm font-light;

  &.scroll-lock {
    overflow: hidden;
  }
}

.intercom-lightweight-app-launcher {
  @media only screen and (max-width: 1024px) {
    bottom: calc(70px + env(safe-area-inset-bottom, 10px) / 2) !important;
  }
}

body.product .intercom-lightweight-app-launcher {
  @media only screen and (max-width: 1024px) {
    bottom: calc(140px + env(safe-area-inset-bottom, 10px) / 2) !important;
  }
}

[class^='intercom-with-namespace-'] {
  @media only screen and (max-width: 1024px) {
    bottom: calc(70px + env(safe-area-inset-bottom, 10px) / 2) !important;
  }
}

body.product [class^='intercom-with-namespace-'] {
  @media only screen and (max-width: 1024px) {
    bottom: calc(140px + env(safe-area-inset-bottom, 10px) / 2) !important;
  }
}

.intercom-lightweight-app {
  z-index: 100 !important;
}

.scroll-to-top {
  bottom: calc(4rem + 10px);
  bottom: calc(4rem + env(safe-area-inset-bottom, 10px) / 2);
}

body.product .scroll-to-top {
  @media only screen and (max-width: 1024px) {
    bottom: calc(140px + env(safe-area-inset-bottom, 10px) / 2);
  }
}

.nav-safearea {
  padding-bottom: 10px;
  padding-bottom: calc(env(safe-area-inset-bottom, 10px) / 2);
  min-height: calc(60px + env(safe-area-inset-bottom, 10px) / 2);
  transition: 300ms;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.cms-page h1:not(.base-disabled),
.cms-page h2:not(.base-disabled),
.cms-page h3:not(.base-disabled),
.cms-page h4:not(.base-disabled),
.cms-page h5:not(.base-disabled),
.cms-page strong:not(.base-disabled) {
  @apply my-[1em] font-bold;
}

.cms-page h1:not(.base-disabled),
.cms-page .h1:not(.base-disabled) {
  @apply my-[1em] text-2xl;
}

.cms-page h2:not(.base-disabled),
.cms-page .h2:not(.base-disabled) {
  @apply my-[1em] text-xl;
}
.cms-page h3:not(.base-disabled),
.cms-page .h3:not(.base-disabled) {
  @apply my-[1em] text-lg;
}
.cms-page h4:not(.base-disabled),
.cms-page .h4:not(.base-disabled) {
  @apply my-[1em] text-base;
}
.cms-page h5:not(.base-disabled),
.cms-page .h5:not(.base-disabled) {
  @apply my-[1em] text-sm;
}
.cms-page h6:not(.base-disabled),
.cms-page .h6:not(.base-disabled) {
  @apply my-[1em] text-xs;
}

.cms-page p:not(.base-disabled) {
  @apply my-[1em];
}

.cms-page ul:not(.base-disabled) {
  @apply my-[1em] list-disc pl-10;
}

input::-webkit-outer-spin-button:not(.quantity),
input::-webkit-inner-spin-button:not(.quantity) {
  -webkit-appearance: none;
  margin: 0;
}

input.quantity::-webkit-outer-spin-button,
input.quantity::-webkit-inner-spin-button {
  margin-right: -0.3rem;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.product-shadow:hover {
  box-shadow: 0px 3px 12px rgb(0 0 0 / 30%);
}
.product-text-shadow {
  text-shadow:
    transparent 0px 0px 1em,
    #fff 0px -0.04em 0px,
    #fff 0.04em 0px 0px,
    #fff 0px 0.04em 0px,
    #fff -0.034em -0.024em 0px,
    #fff -0.03em -0.04em 0px,
    #fff -0.01em -0.04em 0px,
    #fff 0.052em -0.04em 0px,
    #fff 0.04em -0.04em 0px,
    #fff 0.02em -0.04em 0px,
    #fff 0.047em -0.025em 0px,
    #fff -0.045em 0.016em 0px,
    #fff -0.039em 0.038em 0px,
    #fff -0.052em 0.04em 0px,
    #fff -0.042em 0.04em 0px,
    #fff 0.031em 0.028em 0px,
    #fff 0.029em 0.04em 0px,
    rgb(0 0 0 / 40%) 0.05em 0px 0.05em,
    rgb(0 0 0 / 40%) 0px 0.05em 0.05em,
    rgb(0 0 0 / 40%) 0.05em 0.05em 0.05em;
}
.dropdown-shadow {
  box-shadow: #000 0px 3px 12px;
}
.li-shadow {
  box-shadow: 0px 0px 10px rgba(216, 17, 0, 0.4);
}

.related-shadow:hover {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

/*
Material UI
*/
.MuiButton-containedPrimary {
  @apply bg-[#1976d2];
}
.MuiButton-containedSecondary {
  @apply bg-[#9c27b0];
}
.MuiButton-containedInfo {
  @apply bg-[#0288d1];
}
.MuiButton-containedSuccess {
  @apply bg-[#2e7d32];
}
.MuiButton-containedWarning {
  @apply bg-[#ed6c02];
}
.MuiButton-containedError {
  @apply bg-[#d32f2f];
}
/*
Material UI
*/

/* Magento SEO */
.magento-seo {
  a {
    color: #345581;
    position: relative;
    text-decoration: underline;

    &:hover {
      color: #3b73cc;
    }

    em {
      font-style: italic;
    }
  }
}
/* Magento SEO */

/* Frank Rosin */
.cms-page .frank-rosin {
  .mpb-column-group {
    background-color: black;
    padding: 5px 4px;
  }

  .frank-rosin-info {
    background: url('/vsf/frankrosin.webp') black bottom left;
    background-size: contain !important;
    background-repeat: no-repeat;

    @media only screen and (min-width: 768px) {
      padding-left: 224px;
    }

    @media only screen and (max-width: 767px) {
      padding-bottom: 100% !important;
    }

    p {
      margin: 0;
    }
  }

  .pagebuilder-column:not(.frank-rosin-info) {
    width: 30.7692% !important;
  }

  .teaser-headline {
    line-height: 26px;

    &:first-child {
      font-family: 'Roboto Condensed', serif;
    }
  }

  .teaser-text {
    line-height: 20px;
  }

  .card-box {
    padding: 5px;

    div {
      background-color: white;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .pagebuilder-text {
      padding: 0 15px;
    }
  }

  @media only screen and (max-width: 574px) {
    padding-left: 14px;
    padding-right: 14px;
  }

  @media only screen and (max-width: 991px) {
    margin-left: 8px !important;
    margin-right: 8px !important;
    margin-bottom: 30px !important;
  }
}

/* Adyen */
.adyen-checkout__payment-method__name,
.adyen-checkout__button__text,
.mondu-notice,
#leasing-button {
  font-size: 14px;
  @media (max-width: 390px) {
    font-size: 12px;
  }
}

.adyen-checkout__payment-method__radio {
  width: 21px !important;
  height: 21px !important;
}

.adyen-checkout__payment-method__radio--selected {
  background-image: linear-gradient(to top, #0d7d09, #86c48d);
}

.adyen-checkout__payment-method__radio:after {
  display: none !important;
}

.payment-info {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 10px;
  top: 16px;

  .payment-info-tooltip {
    position: relative;
    display: inline-block;

    .info-icon {
      font-size: 16px;
      font-weight: 600;
      color: white;
      background-color: #4b515a;
      border-radius: 50%;
      cursor: pointer;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .tooltiptext {
      display: none;
      width: 300px;
      bottom: 100%;
      right: 0;
      margin-left: -200px;
      background-color: #e2e2e2;
      color: #4b515a;
      line-height: 1.5;
      padding: 10px;
      border-radius: 6px;
      position: absolute;
      z-index: 100;
    }
  }

  .payment-info-tooltip:hover .tooltiptext {
    display: block;
  }
}

.adyen-checkout__payment-method--bankTransfer,
.adyen-checkout__payment-method--cash,
.adyen-checkout__payment-method--debit,
.adyen-checkout__payment-method--offer {
  .adyen-checkout__payment-method__image__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 27px;
      max-height: 16px;
    }
  }
}
.adyen-checkout__payment-method--offer img {
  max-width: 30px;
  max-height: 20px;
}

/* Mondu */
.mondu-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.mondu-loader .spinner {
  width: 24px;
  height: 24px;
  border: 3px solid #ddd;
  border-bottom-color: #8c4aff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: monduRotation 1s linear infinite;
  margin-right: 20px;
}

@keyframes monduRotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.adyen-checkout__payment-method--mondu
  > .adyen-checkout__payment-method__header {
  background: url(/payment_methods/Mondu_logo_primary.svg) no-repeat;
  background-size: 12%;
  background-position: center right 40px;
  @media (max-width: 767px) {
    background-size: 6%;
  }
}

.adyen-checkout__payment-method--mondusepa
  > .adyen-checkout__payment-method__header {
  background: url(/payment_methods/Mondu_logo_primary.svg) no-repeat;
  background-size: 12%;
  background-position: center right 40px;
  @media (max-width: 767px) {
    background-size: 6%;
  }
}

.adyen-checkout__payment-method--monduinstallment
  > .adyen-checkout__payment-method__header {
  background: url(/payment_methods/Mondu_logo_primary.svg) no-repeat;
  background-size: 12%;
  background-position: center right 40px;
  @media (max-width: 767px) {
    background-size: 6%;
  }
}
