@import 'slick-carousel/slick/slick.css';
/* @import '~slick-carousel/slick/slick-theme.css'; */

@charset "UTF-8";

/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;

  display: none;

  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 1;
}
.slide-arrow {
  display: none;
}
.slick-slider:hover .slide-arrow {
  display: block;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;

  opacity: 0.75;
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto;
}
.slick-prev:before {
  content: '←';
}
[dir='rtl'] .slick-prev:before {
  content: '→';
}

.slick-next {
  right: -25px;
}
[dir='rtl'] .slick-next {
  right: auto;
  left: -25px;
}
.slick-next:before {
  content: '→';
}
[dir='rtl'] .slick-next:before {
  content: '←';
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  transition:
    transform 0.3s ease,
    opacity 0.3s ease;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: inline-block;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 8px;
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 8px;
  content: '•';
  text-align: center;
  opacity: 0.2;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: gray;
}

.slick-dots > li > button:before {
  @apply text-[30px];
}
.slick-dots {
  @apply !pl-0;
  @apply !bottom-[-35px];
}
.slick-track {
  @apply !ml-0 !mr-0 !flex;
  will-change: transform;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
.slick-slide {
  height: inherit !important;
}
.slick-slide div {
  @apply !h-full;
}

/* Related products slider */

.related-slider .slick-slide {
  @apply !mx-[5px];
}

.related-slider .slick-slide:first-child {
  @apply !ml-0;
}

.related-slider .slick-slide:last-child:only-child {
  @apply !mr-[10px] !pl-0;
}

@media (max-width: 1200px) {
  .related-slider .slick-slide {
    @apply !mx-0 !pr-[5px];
  }
  .related-slider .slick-slide:nth-child(even) {
    @apply !pl-[5px] !pr-0;
  }

  .related-slider .slick-slide:nth-last-child(1):nth-child(odd) {
    @apply !pl-[5px] !pr-0;
  }

  .related-slider .slick-slide:last-child:only-child {
    @apply !mr-[5px] !pl-0;
  }
}

/* Addition related to lazyload for performance */
.slick-slide:not(.slick-active) {
  visibility: hidden;
  pointer-events: none;
}

.slick-slide.slick-active {
  visibility: visible;
  pointer-events: auto;
}
